import { template as template_d26b22f6d5fb47feb147263d9fd68a91 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d26b22f6d5fb47feb147263d9fd68a91(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
