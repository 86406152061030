import { template as template_dba263498e8840c78a5995703e001e30 } from "@ember/template-compiler";
const SidebarSectionMessage = template_dba263498e8840c78a5995703e001e30(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
